import Vue from 'vue';
import axios from "@/axios";
import tinycolor from "tinycolor2";

/* 
  Color sets:
    - tenant_config - colors and other settings from the server.
    - defaultColors - hardcoded default colors.
    - colors - applied on the frontend.
*/

const focusColors = ['primary', 'secondary', 'accent', 'neutral'];

/*
  To add a new shade to color add a new color to:
  - state.colors
  - its default value to shared/assets/styles/style.scss
  - new color to tailwind.config.js theme.extend.colors
*/
const colorShades = [
  {
    postfix: '100',
    make: (color) => tinycolor(color).lighten(45).toHex8String(),
  },
  {
    postfix: '200',
    make: (color) => tinycolor(color).lighten(25).toHex8String(),
  },
  {
    postfix: '300',
    make: (color) => tinycolor(color).lighten(12).toHex8String(),
  },
  {
    postfix: '400',
    make: (color) => tinycolor(color).lighten(3).toHex8String(),
  },
  {
    postfix: '500',
    make: (color) => tinycolor(color).darken(2).toHex8String(),
  },
  {
    postfix: '600',
    make: (color) => tinycolor(color).darken(5).toHex8String(),
  },
  {
    postfix: '700',
    make: (color) => tinycolor(color).darken(9).toHex8String(),
  },
  {
    postfix: '800',
    make: (color) => tinycolor(color).darken(12).toHex8String(),
  },
]

export default {
  state: {
    // settings from the server
    client_config: {},
    // current frontend settings:
    theme: {
      defaultColors: {
        'main_color': { // primary
          var: '--theme-color-main',
          value: '#0D69D5'
        },
        'table_header_footer': {
          var: '--theme-color-thead',
          value: '#029ADB'
        },
        'section_selection': {
          var: '--theme-color-selection',
          value: '#029ADB'
        },
        'page_background': {
          var: '--theme-color-page-bg',
          value: '#EDF2F7'
        },
        'text_menu_icon': {
          var: '--theme-color-text',
          value: '#282828'
        },
        'line_sepration': {
          var: '--theme-color-line',
          value: '#DDDDDD'
        },
        'box_stroke_color': {
          var: '--theme-color-box-stroke',
          value: '#DDDDDD'
        },
        'white_text': {
          var: '--theme-color-white-text',
          value: '#FFFFFF'
        },
        'card_bg': {
          var: '--theme-card-background',
          value: '#ffffff'
        }
      },
      // temporary frontend colors:
      colors: {
        'main_color': { // primary
          name: 'Main Color',
          var: '--theme-color-main',
          daisyui: ['primary', 'accent'],
          shades: 'primary',
        },
        'accent': {
          var: '--a',
        },
        'accent-focus': {
          var: '--af',
        },
        'primary': {
          var: '--p',
        },
        'primary-focus': {
          var: '--pf',
        },
        'primary-100': {
          var: '--theme-color-primary-100',
        },
        'primary-200': {
          var: '--theme-color-primary-200',
        },
        'primary-300': {
          var: '--theme-color-primary-300',
        },
        'primary-600': {
          var: '--theme-color-primary-600',
        },
        'opacity': {
          var: '--tw-bg-opacity'
        },
        'table_header_footer': {
          name: 'Table Header/Footer',
          var: '--theme-color-thead',
        },
        'section_selection': {
          name: 'Section Selection',
          var: '--theme-color-selection',
        },
        'page_background': {
          name: 'Page Background',
          var: '--theme-color-page-bg',
        },
        'text_menu_icon': {
          name: 'Text & Menu Icon',
          var: '--theme-color-text',
          daisyui: ['base-content'],
          shades: 'base-content',
        },
        'base-content': {
          var: '--bc',
        },
        'base-content-300': {
          var: '--theme-color-text-300',
        },
        'base-content-600': {
          var: '--theme-color-text-600',
        },
        'base-content-800': {
          var: '--theme-color-text-800',
        },
        'line_sepration': {
          name: 'Line Separation',
          var: '--theme-color-line',
        },
        'box_stroke_color': {
          name: 'Box Stroke Color',
          var: '--theme-color-box-stroke',
        },
        'white_text': {
          name: 'White Text',
          var: '--theme-color-white-text',
          daisyui: ['primary-content', 'secondary-content', 'accent-content', 'neutral-content'],
          shades: 'white_text',
        },
        'white_text-300': {
          var: '--theme-color-white-text-300',
        },
        'white_text-600': {
          var: '--theme-color-white-text-600',
        },
        'primary-content': {
          var: '--pc',
        },
        'secondary-content': {
          var: '--sc',
        },
        'accent-content': {
          var: '--ac',
        },
        'neutral-content': {
          var: '--nc',
        },
        'card_bg': {
          var: '--theme-card-background',
          name: 'Card Background',
        }
      },
      logo: '',
      tenant_logo: '',
      use_default: false,
      tenant_logo_loading: true,
    }
  },
  getters: {
    getCLientConfig: (state) => state.client_config,
    getThemeColors: (state) => state.theme.colors,
    getDefaultColors: (state) => state.theme.defaultColors,
    getThemeLogo: (state) => state.theme.logo,
    getTenantLogo: (state) => state.theme.tenant_logo,
    // getUserPermissions: (state) => state.userPermissions.map(el => el.code),
    // getIsPermission: (state) => state.isPermission,
    getTenantLogoLoading: (state) => state.theme.tenant_logo_loading

  },

  mutations: {
    SET_CLIENT_CONFIG(state, payload) {
      Vue.set(state, 'client_config', payload);
      Vue.set(state.theme, 'use_default', payload.reset_flag);
      if (payload.reset_flag) return;
      
      const serverColors = state.client_config.uiconfig.color_config;
      const colors = state.theme.colors;
      Object.entries(serverColors).forEach(([key, value]) => {
        if (!value) return;
        Vue.set(colors[key], 'value', value);
        const dColors = colors[key].daisyui;
        // daisyui variables resetting:
        if (dColors) {
          const { h, s, l } = tinycolor(value).toHsl();
          const daisyuiColor = `${h} ${Math.round(s*100)}% ${Math.round(l*100)}%`;
          const { h: hf, s: sf, l: lf } = tinycolor(value).lighten(8).toHsl();
          const daisyuiFocusColor = `${hf} ${Math.round(sf*100)}% ${Math.round(lf*100)}%`;

          dColors.forEach((dColor) => {
            Vue.set(colors[dColor], 'value', daisyuiColor);
            // new focus color:
            if (focusColors.includes(dColor)) {
              Vue.set(colors[`${dColor}-focus`], 'value', daisyuiFocusColor);
            }
          });
        }
        const shades = colors[key].shades;
        // color shades:
        if (shades) {
          colorShades.forEach(shade => {
            const vName = `${shades}-${shade.postfix}`;
            if (colors[vName]) {
              Vue.set(colors[vName], 'value', shade.make(value));
            }
          })
        }
      });
    },
    SET_THEME_USE_DEFAULT(state, payload) {
        Vue.set(state.theme, 'use_default', payload);
    },
    SET_THEME_LOGO(state, payload) {
      state.theme.logo = payload;
    },
    SET_TENANT_THEME_LOGO(state, payload) {
      state.theme.tenant_logo = payload
    },
    // SET_USER_PERMISSIONS(state, payload) {
    //   state.userPermissions = payload;
    //  },
    // CHECK_PERMISSION_UPDATED(state, value) {
    //   state.isPermission = value
    // }
    SET_TENANT_LOGO_LOADER(state, payload) {
      state.theme.tenant_logo_loading = payload
    }

  },

  actions: {
    fetchUIConf: async ({ commit }) => {
        try {
          const { data } = await axios.get(`client/uiconfig/client_logo`);
          if(data && typeof data.uiconfig == 'string') {
            data.uiconfig = JSON.parse(data.uiconfig)
          }
        //   let color_config = JSON.parse(data.tenant_uiconfig)
        //   color_config.logo_url = data.tenant_logo || ""
          commit("SET_CLIENT_CONFIG", data);
          commit("SET_THEME_LOGO", data.logo_url)
          // commit("SET_THEME_LOGO", data.tenant_logo_url)
          commit("SET_TENANT_THEME_LOGO", data.tenant_logo_url)
          commit("SET_TENANT_LOGO_LOADER", false)

          return data;
        } catch (error) {
          console.log(" error", error);
        }
      },
      // async fetchPermissions({commit}) {
      //   debugger; // eslint-disable-line no-debugger
      //   try {
      //     const { data } = await axios(`user/permissions`) 
      //     if (data) {
      //       commit("SET_USER_PERMISSIONS", data || [])
      //       commit("CHECK_PERMISSION_UPDATED", true)
      //     }
      //   } catch (error) {
      //     console.log('error :>> ', error);
      //   }
      // }
  }

}