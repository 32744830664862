import osintAxios from "./axios/osint-axios";
import blackboxAxios from "./blackbox-axios";
import { default as rawAxios } from "axios";
import { getBaseUrlDelviumBackend } from "@shared/utils/functions";
import { default as ax } from "axios";
import VueCookies from "vue-cookies";
import delvAxios from "./axios";
import backendaxios from "./axios";


export const fetchOffshoreRecords = async (payload) => {
  let sourceBody = {
    name: payload.name,
    sources: ["offshoreleaks"],
    age: [],
    search_mode: "exact_match",
    icij_entity_type: [],
    limit: 100,
    page: 1,
  };
  const url = "/api/v1/adverse-media/icij-search";
  const result = await getResultQueryID(sourceBody);
  let newPayload = { ...payload, request_id: result.data.request_id };
  return await osintAxios.post(url, newPayload);
};

export const fetchAdverseMedia = async (payload) => {
  const url = "/api/v1/adverse-media/search";
  const result = await getResultQueryID({
    ...payload,
    sources: ["complyadvantage"],
  });
  let newPayload = { ...payload, request_id: result.data.request_id };
  return await osintAxios.post(url, newPayload);
};

export async function getResultQueryID(payload) {
  const url = "/api/v1/adverse-media/search/sources";
  return await osintAxios.post(url, payload);
}

export async function getOffshoreResultDetail(payload) {
  const url = "/api/v1/adverse-media/icij-links";
  return await osintAxios.post(url, payload);
}

export async function saveResult(payload) {
  const url = "/api/v1/osint-tools/card/mark-as-saved";
  return await blackboxAxios.post(url, payload);
}

export async function fetchAllQueryResult(payload) {
  const url = "/api/v1/osint-tools/all/query/results";
  return await blackboxAxios.post(url, payload);
}

export async function fetchToolStatus(payload) {
  const url = "/api/v1/tools-status";
  return await blackboxAxios.post(url, { ...payload });
}

export async function getSaasToken(client_id, token) {
  const url = "/api/v1/user/saas-token";
  return await blackboxAxios.post(
    url,
    { client_id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

export async function getSaasUrl(payload, saasToken) {
  const base = process.env.VUE_APP_BLACKBOX_API_BASE_URL;
  const url = `${base}api/v1/internal-service/scratch-case`;
  return await ax.post(url, payload, {
    headers: { Authorization: `Bearer ${saasToken}` },
  });
}

export async function createNewNeotascase(payload) {
  const url = "/api/v1/user/saas-token";
  return await blackboxAxios.post(url, payload);
}

export async function fetchRefreshToken(token) {
  const base = getBaseUrlDelviumBackend();
  const url = `${base}/user-auth/token`;
  // const url = `/api/v1/user-auth/token`;
  try {
    let response = await rawAxios.post(url, { token });
    return response.data.access_token;
  } catch (error) {
    console.error(error);
    throw new Error("Refresh token failed from server!");
  }
}

export async function fetchRefreshClientToken(token) {
  const base = getBaseUrlDelviumBackend();
  const url = `${base}/client-auth/token`;
  // const url = `/api/v1/user-auth/token`;
  try {
    let response = await rawAxios.post(url, { token });
    return response.data.access_token;
  } catch (error) {
    console.error(error);
    throw new Error("Refresh token failed from server!");
  }
}

export async function fetchRefreshAccessToken(access_token, refresh_token) {
  const base = getBaseUrlDelviumBackend();
  const url = `${base}/user-auth/access-from-refresh`;
  // const url = `/api/v1/user-auth/token`;
  try {
    let response = await rawAxios.post(url, {
      access_token: access_token,
      refresh_token: refresh_token,
    });
    return response.data.data.access_token;
  } catch (error) {
    console.error(error);
    throw new Error("Refresh token failed from server!");
  }
}

export async function fetchRefreshSaasToken(token) {
  const base = getBaseUrlDelviumBackend();
  const url = `${base}/user/saas-token`;
  // const url = `/api/v1/user-auth/token`;
  try {
    let response = await rawAxios.post(url, { token });
    return response.data.access_token;
  } catch (error) {
    console.error(error);
    throw new Error("Refresh token failed from server!");
  }
}

// Search API By Company Name and Domain

export async function getSearchedRecords(payload) {
  const url = "/api/v1/company/search";
  return await osintAxios.post(url, payload, {
    headers: {
      "x-tool-name": "company-name",
      "x-case-id": payload.neo_case_id,
      "x-status": "RUNNING",
    },
  });
}

export async function getSearchedDomainRecords(payload) {
  const url = "/api/v1/domain/registration/domain-name";
  return await osintAxios.post(url, payload, {
    headers: {
      "x-tool-name": "company-domain",
      "x-case-id": payload.neo_case_id,
      "x-status": "RUNNING",
    },
  });
}

//Inteligence API and Status Api hit on every 10 sec until get checkstatus true
// x-delvium-token
export async function getRunInteligenceDetails(payload) {
  const url = "/api/v1/social-media-automation/case-run-intelligence";
  let delviumToken = VueCookies.get("token");
  let refresh_token = VueCookies.get("refresh_token");
  const isclient = VueCookies.get("isClient") === "true" ? true : false;

  if (isclient) {
    delviumToken = await fetchRefreshClientToken(delviumToken);
  } else {
    delviumToken = await fetchRefreshAccessToken(delviumToken, refresh_token);
    VueCookies.set("token", delviumToken);
  }
  return await blackboxAxios.post(url, payload, {
    headers: { "x-delvium-token": delviumToken },
  });
}
export async function getInteligenceStatus(payload) {
  const url = `/api/v1/social-media-automation/check-status/${payload.case_id}`;
  return await blackboxAxios.get(url);
}

//Anlysis-status service
export async function getAnalysisStatus(p) {
  const url = `/api/v1/case-analysis/${p.case_id}/analysis?tool=search-engine-tool`;
  return await blackboxAxios.get(url, {
    headers: { "x-tool-name": "search-engine-tool", "x-case-id": p.case_id },
  });
}

// Entity Data Api after Inteligence API check status true
export async function getEntityIdentifiers(payload) {
  const url = `/api/v1/social-media-automation/delvium-entities-data`;
  return await blackboxAxios.post(url, payload);
}

// Jurisdiction List
export async function getjurisdictionList() {
  const url = "/api/v1/company/search/jurisdiction?cache_bypass=false";
  return await osintAxios.get(url);
}

//internet search API for internet details
export async function getinternetDataDetails(payload) {
  const url = "api/v1/search-engine/multiqueryresults";
  return await blackboxAxios.post(url, payload, {
    headers: {
      "x-tool-name": "company-name",
      "x-case-id": payload.case_id,
      "x-status": "RUNNING",
    },
  });
}

//master API for risk categories
export async function getriskcategories(payload) {
  const url = "api/v1/risk-categories";
  return await blackboxAxios.get(url, {
    headers: {
      "x-tool-name": "company-name",
      "x-case-id": payload.case_id,
      "x-status": "RUNNING",
    },
  });
}

//master API for relation attributes
export async function getrelationAttributes() {
  const url = "api/v1/entities-relations-attributes";
  return await blackboxAxios.get(url);
}

//Update API for entity data update
export async function updateEntityDetails(payload, doc_id) {
  const url = `api/v1/social-media-automation/delvium-entities-data/${doc_id}`;
  return await blackboxAxios.put(url, payload, {
    headers: { "x-case-id": payload.case_id, "x-status": "RUNNING" },
  });
}

//internet update API
export async function updateInternetDataDetails(payload, doc_id) {
  const url = `api/v1/search-engine/${doc_id}`;
  return await blackboxAxios.put(url, payload, {
    headers: {
      "x-tool-name": "company-name",
      "x-case-id": payload.case_id,
      "x-status": "RUNNING",
    },
  });
}

//Upload Image on S3 bucket
export async function uploadAttachment(payload, case_id) {
  const url = `api/v1/uploads/reports/images`;
  return await blackboxAxios.post(url, payload, {
    headers: { "x-case-id": case_id, "x-status": "RUNNING" },
  });
}

//Details APIs services
export async function getAdverseDetails(payload) {
  const url = "/api/v1/adverse-media/comply-advantage/details";
  return await osintAxios.post(url, payload, {
    headers: {
      "x-tool-name": "adverse-media-tool",
      "x-case-id": payload.case_id,
      "x-status": "RUNNING",
    },
  });
}

export async function getInternetDetails(payload, case_id) {
  const url = `/api/v1/google-search-data/search-data/${payload.doc_id}`;
  return await blackboxAxios.get(url, {
    headers: { "x-case-id": case_id, "x-status": "RUNNING" },
  });
}

export async function getOffshoreDetails(payload) {
  const url = "/api/v1/adverse-media/offshoreleaks/details";
  return await osintAxios.post(url, payload, {
    headers: {
      "x-tool-name": "offshoreleaks-tool",
      "x-case-id": payload.case_id,
      "x-status": "RUNNING",
    },
  });
}

export async function getNewsDetails(payload) {
  const url = "/api/v1/news/news/details";
  return await osintAxios.post(url, payload, {
    headers: {
      "x-tool-name": "news-tool",
      "x-case-id": payload.case_id,
      "x-status": "RUNNING",
    },
  });
}

export async function getCaseDetail(case_id) {
  let base = process.env.VUE_APP_API_BASE_URL;
  const url = `${base}/api/v1/case/${case_id}/details`;
  return await ax.get(url, {
    headers: {
      "x-tool-name": "company-name",
      "x-case-id": case_id,
      "x-status": "RUNNING",
    },
  });
}

export async function getQueryDetails(case_id) {
  try {
    const url = `/api/v1/tools-status/company-name/${case_id}/`;
    return await blackboxAxios.get(url, {
      headers: { "x-case-id": case_id, "x-status": "RUNNING" },
    });
  } catch (err) {
    console.log(err);
  }
}

export async function getSaasTokenRedirect(payload, token) {
  let base = process.env.VUE_APP_API_BASE_URL;
  const url = `${base}/api/v1/user/saas-token`;
  return await ax.post(url, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function insertGraphNodes(payload) {
  try {
    const url = `/api/v1/analysis-tools/graph`;
    return await blackboxAxios.post(url, payload, {
      headers: { "x-case-id": payload?.case_id, "x-status": "RUNNING" },
    });
  } catch (err) {
    console.log(err);
  }
}

// export async function getGraphData(payload,case_id) {
//     try{
//         const url = `/api/v1/analysis-tools/${case_id}/graph-extended`
//         return await blackboxAxios.post(url,payload,{headers: {"x-case-id": case_id,"x-status":"RUNNING"}});
//     }catch(err){
//         console.log(err);
//     }
// }

// keylines graph data call by case Id

export async function getGraphData(payload, case_id) {
  try {
    const url = `/api/v1/analysis-tools/keylines_graph_data/${case_id}`;
    return await blackboxAxios.post(url, payload, {
      headers: { "x-case-id": case_id, "x-status": "RUNNING" },
    });
  } catch (err) {
    console.log(err);
  }
}

//count for entity
export async function getEntityMenuCount(case_id, payload) {
  try {
    const url = `api/v1/social-media-automation/${case_id}/tools-results-count`;
    return await blackboxAxios.post(url, payload, {
      headers: { "x-case-id": case_id, "x-status": "RUNNING" },
    });
  } catch (err) {
    console.log(err);
  }
}

//judyRecord data API
export async function getJudyRecord(payload) {
  try {
    const url = "api/v1/social-media-automation/unicourt-data";
    return await blackboxAxios.post(url, payload);
  } catch (err) {
    console.log("error", err);
  }
}

//Internal filter Data API
export async function getJudyRecordFilter(payload, case_id) {
  try {
    const url = `api/v1/social-media-automation/${case_id}/unicourt-filter`;
    return await blackboxAxios.post(url, payload);
  } catch (err) {
    console.log("error", err);
  }
}

//Plantif data get
export async function getPlantifData(payload, case_id) {
  try {
    const url = `/api/v1/court/case_parties`;
    return await osintAxios.post(url, payload, {
      headers: {
        "x-case-id": case_id,
        "x-status": "RUNNING",
        "x-tool-name": "court-records-tool",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
}

//Details of Attorney for unicourt
export async function getAttorneyDetails(payload, case_id) {
  try {
    const url = `api/v1/court/case_attroney?cache_bypass=false`;
    return await osintAxios.post(url, payload, {
      headers: {
        "x-case-id": case_id,
        "x-status": "RUNNING",
        "x-tool-name": "court-records-tool",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
}

//Docket entries details
export async function getJudesDetails(payload, case_id) {
  try {
    const url = `api/v1/court/case_docket_entries?cache_bypass=false`;
    return await osintAxios.post(url, payload, {
      headers: {
        "x-case-id": case_id,
        "x-status": "RUNNING",
        "x-tool-name": "court-records-tool",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
}

export async function getJudgyData(payload, case_id) {
  try {
    const url = `/api/v1/court/case_judges`;
    return await osintAxios.post(url, payload, {
      headers: {
        "x-case-id": case_id,
        "x-status": "RUNNING",
        "x-tool-name": "court-records-tool",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
}

export async function saveUnicourtData(payload, case_id) {
  try {
    const url = `/api/v1/social-media-automation/save-unicourt-detail`;
    return await blackboxAxios.post(url, payload, {
      headers: {
        "x-case-id": case_id,
        "x-status": "RUNNING",
        "x-tool-name": "court-records-tool",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
}

export async function savejudyData(payload) {
  try {
    const url = `/api/v1/social-media-automation/save-judyrecords-detail`;
    return await blackboxAxios.post(url, payload);
  } catch (err) {
    console.log("error", err);
  }
}

//fetch judy-details for scraped-details
export async function getjudyScrapedData(payload, case_id) {
  try {
    const url = `api/v1/scraping/judyrecords-fetch-details`;
    return await osintAxios.post(url, payload, {
      headers: {
        "x-case-id": case_id,
        "x-status": "RUNNING",
        "x-tool-name": "court-records-tool",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
}

// create case
export async function createCase(payload) {
  const url = "/case/automated";
  return await delvAxios.post(url, payload);
}

// add maunal entity person or comapny
export async function addManualData(payload) {
  try {
    const url = `api/v1/delvium-case-data`;
    return await blackboxAxios.post(url, payload);
  } catch (err) {
    console.log("error", err);
  }
}

// get manual maunal entity person or comapny
export async function getManualData(case_id) {
  try {
    const url = `api/v1/delvium-case-data/${case_id}`;
    return await blackboxAxios.get(url);
  } catch (err) {
    console.log("error", err);
  }
}
// delete manual maunal entity person or comapny
export async function deleteManualData(entity_id) {
  try {
    const url = `api/v1/delvium-case-data/${entity_id}`;
    return await blackboxAxios.delete(url);
  } catch (err) {
    console.log("error", err);
  }
}

// update manual maunal entity person or comapny
export async function updateManualData(entity_id, payload) {
  try {
    const url = `api/v1/delvium-case-data/${entity_id}`;
    return await blackboxAxios.put(url, payload);
  } catch (err) {
    console.log("error", err);
  }
}

//master Api for positions of officers
export async function getPositions() {
  try {
    const url = `api/v1/delvium-case-data/officer-position`;
    return await blackboxAxios.get(url);
  } catch (err) {
    console.log("error", err);
  }
}

// Run intelligence for manual added people or comapany
export async function runIntelligenceCards(payload) {
  try {
    const url = `api/v1/social-media-automation/run-intelligence-cards`;
    return await blackboxAxios.post(url, payload);
  } catch (err) {
    console.log("error", err);
  }
}

// Run intelligence for manual added people or comapany
export async function maunalRunIntelligence(payload) {
  try {
    const url = `api/v1/social-media-automation/run-intelligence`;
    let delviumToken = VueCookies.get("token");
    let refresh_token = VueCookies.get("refresh_token");
    const isclient = VueCookies.get("isClient") === "true" ? true : false;

    if (isclient) {
      delviumToken = await fetchRefreshClientToken(delviumToken);
    } else {
      delviumToken = await fetchRefreshAccessToken(delviumToken, refresh_token);
      VueCookies.set("token", delviumToken);
    }
    return await blackboxAxios.post(url, payload, {
      headers: { "x-delvium-token": delviumToken },
    });
  } catch (err) {
    console.log("error", err);
  }
}

export async function graphParentRelationship(payload) {
  try {
    const url = `api/v1/analysis-tools/create_parent_relationship`;
    return await blackboxAxios.post(url, payload, {
      headers: { "x-case-id": payload.case_id },
    });
  } catch (err) {
    console.log("error", err);
  }
}

export async function saveToolGraphData(payload) {
  try {
    const url = `api/v1/analysis-tools/save-tool-graph-data`;
    return await blackboxAxios.post(url, payload, {
      headers: { "x-case-id": payload.case_id },
    });
  } catch (err) {
    console.log("error", err);
  }
}

export async function getLicenseIds() {
    try {
        const url = `/source-consume-callback/get_license_details`;
        return await backendaxios.get(url);
    } catch(err) {
        console.error(err);
        return null;
    }
}

export async function getAllFeatures(ids) {
        let url = `api/v1/automatic/license/details?license_ids=${ids[0]}`;
        if(ids.length > 1)  {
          ids.slice(1).forEach((id) => {
                url = url+`&license_ids=${id}`
            })
        }
        return await blackboxAxios.get(url);
}

export async function getInternetSearchCategory() {
    const url = `/api/v1/search-engine/product/product-category-map?current_page=1&page_size=10`;
    return await blackboxAxios.get(url);
}

export async function postEachEntitySelectedToolData(payload) {
    const url = `api/v1/automatic/single-entity-selected-tool`;
    return await blackboxAxios.post(url, payload)
}
export async function updateCaseName(payload, case_id) {
  const url = `/case/${payload.neo_case_id}`;
  delete payload.neo_case_id;
  return await delvAxios.put(url, payload, {
    headers: {
      "x-tool-name": "company-name",
      "x-case-id": case_id,
      "x-status": "RUNNING",
    },
  });
}

export async function getSelectedToolState(case_id) {
  const url = `api/v1/automatic/${case_id}/selected-tool`;
  return await blackboxAxios.get(url);
}
