import axios from "@/axios";
import { default as rawAxios } from "axios";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"

import store from "@/store";
import JWT from "jsonwebtoken";
async function logoutFromSaas() {
    const url = `client-auth/logout`;
    try {
        const { data } = await axios.get(url);
        console.log('data :>> ', data);
        return data.logout_redirect_uri;
    } catch (error) {
        throw "LogoutFailed";
    }
    // return true;
}

function decodeToken(token) {
    const decoded = JWT.decode(token);
    return decoded;
}

/*

@param {sting} token - access token.
@returns {bool} -
*/
function validateTokenLocal(token) {
    // let decodedToken;
    // await store.dispatch("fetchPermissions")
    try {
        return decodeToken(token);
    } catch (error) {
        console.warn("decode jwt local error ->", error);
        return false;
    }
}

async function validateAndSetAuth(token, refresh_token) {
    let decoded = validateTokenLocal(token);
    try {
        if (decoded) {
            const expiry = decoded.exp * 1000;
            const now = Date.now();
            if (now >= expiry) {
                // token is expired
                const new_token = await fetchRefreshToken(token, refresh_token);
                store.dispatch("setNewUserToken", { access_token: new_token, refresh_token });
                return false;
            } else {
                store.commit("SET_USER_TOKEN", { access_token: token, refresh_token });
                return true;
            }
        } else {
            store.dispatch("logoutUser");
            return false;
        }
    } catch (error) {
        console.log(error.message || "validate token locally failed error");
    }
    // if (validateTokenLocal(token)) return true
    // let validatedToken = await validateTokenRemote(token);
    // if (validatedToken?.error) {
    //     if (validatedToken.error.type === "invalid") {
    //         store.dispatch("logoutUser");
    //     }
    //     if (validatedToken.error.type === "expired") {
    //         try {
    //             const _token = await fetchRefreshToken(token);
    //             let decodedToken = decodeToken(_token);
    //             store.dispatch("setLoginSession", { decodedToken, token: _token });
    //         } catch (error) {
    //             // if refresh token throws an error logout the user
    //             console.error(error);
    //             store.dispatch("logoutUser");
    //         }
    //     }
    // } else {
    //     // token is valid
    //     const tokenVerifiedByLocalCert = validateTokenLocal(token);
    //     if (tokenVerifiedByLocalCert) {
    //         let decodedToken = decodeToken(token);
    //         store.dispatch("setLoginSession", { decodedToken, token: token });
    //         // yay!
    //     } else {
    //         // oops !
    //         store.dispatch("logoutUser");
    //     }
    // }
}
async function fetchRefreshToken(token, refresh_token) {
    // const url = `/api/v1/client-auth/token`;
    const base = getBaseUrlDelviumBackend()
    const url = `${base}/client-auth/token`;
    try {
        // let config = {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     }
        // }
        let response = await rawAxios.post(url, { token, refresh_token });
        return response.data.access_token;
    } catch (error) {
        console.error(error);
        // throw new Error("Refresh token failed from server!");
    }
}

const getLoginInfo = async () => {
    try {
        const url = `/tenant/get-tenant-information`;
        const { data } = await axios.get(url);
        if(data) store.commit("setLoginScreenInfo", data);
    } catch (error) {
        console.log(error);
        return error
    }
};
export { logoutFromSaas, validateAndSetAuth, validateTokenLocal, fetchRefreshToken, getLoginInfo };
