<template>
  <div class="printingthis">
    <div class="hideIt flex flex-col">
      <div class="text-xs"><b>Referee Name:</b></div>
      <div class="text-xs">{{ refName }}</div>
    </div>
   <div class="m-1 relative" >
    <!-- Signature -->
    <div class="hideIt mainSign text-xs"><b>Signature</b></div>
    <VueSignaturePad
      :value="getValue.value"
      ref="signaturePad"
      id="signature"
      width="100%"
      class="
        mt-1
        refereeSign
        form-control
        max-w-full
        px-0
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        rounded-xl
        transition
        ease-in-out
        m-0
        resize-y
        focus:text-gray-700
        focus:bg-white
        focus:border-blue-600
        focus:outline-none
        ref-signaturePad
      "
      rows="3"
      :options="{ onEnd }"
      @input="handleChange"
    />
    <!-- <div class="flex gap-3 mt-2 absolute -top-11 right-1 self-end">
      <button
        v-if="currValue"
        @click="clear"
        title="Clear signature"
        class="btn btn-circle btn-outline btn-xs btn-primary"
        v-tippy
        content="Clear"
      >
        <font-awesome-icon icon="times" />
      </button>
      <button
        v-if="currValue"
        @click="undo"
        title="Undo"
        class="btn btn-circle btn-xs btn-primary"
        v-tippy
        content="Undo"
      >
        <font-awesome-icon icon="undo" />
      </button>
    </div> -->
    <span class="p-4 font-medium mt-1 print--none" v-if="getDateValue">
      Date: {{getDateValue}}
    </span>
    <div class="flex justify-between">
      <div class="flex gap-3 mt-2 self-end print--none">
        <button
          v-if="getValue.value"
          @click="clear"
          title="Clear signature"
          class="cursor-pointer h-7 border border-gray-700 w-20 min-w-min px-3 hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center disabled:opacity-50"
          v-tippy
          content="Clear"
          :disabled="isDisabled"
        >
          Clear
        </button>
        <button
          v-if="getValue.value"
          @click="undo"
          title="Undo"
          class="cursor-pointer h-7 border border-gray-700 w-20 min-w-min px-3 hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center disabled:opacity-50"
          v-tippy
          content="Undo"
          :disabled="isDisabled"
        >
          Undo
        </button>
      </div>
    </div>
  </div>
  <div class="hideThis flex flex-col">
   <h6 class="text-xs"><b>Date:</b></h6> <span class="text-xs">{{getDateValue}}</span>
  </div>
  <div class="flex  items-center">
        <template  v-if="ip && currValue">
          <span class="ml-2">
            {{ ip }}
          </span>
        </template>
    </div>
</div>
</template>

<script>
import { VueSignaturePad } from "vue-signature-pad";
import { DateTime as dt } from "luxon"
// import { isJson } from '../../utils';
import axios from "@/axios"

export default {
  components: {
    VueSignaturePad,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currValue: undefined,
      refName:'',
      ip: '',
      isSignWithIp: false,
    };
  },
  computed: {
    getValue: {
      get() {
        return this.context.model ? JSON.parse(this.context.model) : {};
      },
      set(newValue) {
        const result = {
          value: newValue,
          date: newValue ? Date.now() : "",
          ip: this.isSignWithIp && this.currValue ? this.ip : null
        };
        this.context.model = JSON.stringify(result);
      },
    },
    getDateValue() {
      if (this.getValue.date){
      let date = new Date(this.getValue.date) || null
      if (date) date = dt.fromJSDate(date).toFormat('dd-LLL-yyyy')
      return date
    }else{
      return false
    }
    },
    isDisabled() {
      return this.context?.attributes?.disabled ?? false
    },
  },
 async mounted() {
   // adding an event for window resizing.
   window.addEventListener('resize', this.handleResize);
   this.modifySignatureCanvas();
   this.$refs.signaturePad.fromDataURL(this.getValue.value);
   if(this.context?.attributes?.disabled) {
     this.$refs.signaturePad.lockSignaturePad()
    }
    let case_id = this.$store.getters.getCaseId
    let case_reference_id = this.$store.getters.getCaseReferenceId
    try {
          if (case_id && case_reference_id) {
            const { data } = await axios(`referee/referee/form/${case_id}/${case_reference_id}`)
            const { referee_details } = data
            this.refName = referee_details.referee_name  
          } 
    } catch (error) {
        this.$toast.error((error && error.response) ? error.response.data.detail : 'Something went wrong!')
        console.log('Error fetchFormData ', error)
    }
    this.isSignWithIp = this.context?.attributes?.isSignWithIp;
    this.ip = this.isSignWithIp ? this.context?.attributes?.ip : null;
  },
  methods: {
    handleResize() {
    // Save the signature data before resizing
    this.modifySignatureCanvas();
    this.$refs.signaturePad.fromDataURL(this.getValue.value);
    },
    modifySignatureCanvas() {
      let canvas = this.$refs?.signaturePad.$refs.signaturePadCanvas;
      let ratio =  window.devicePixelRatio;
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
    },
    handleChange({ target }) {
      this.getValue = target.value;
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.getValue={};
      this.modifySignatureCanvas();
      this.onEnd();
    },
    undo() {
      this.modifySignatureCanvas();
      this.$refs.signaturePad.undoSignature();
      this.onEnd();
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.currValue = data;
      this.getValue = data;
      this.$emit("currValue",data);
    },
  }
};
</script>

<style scoped>
.ref-signaturePad{
  height:150px !important;
}
.hideIt{
  display: none;
}
.hideThis{
  display: none;
}
</style>