import Vue from "vue";
import "keylines";
import App from "./App.vue";
import '@shared/assets/styles/style.scss'
import store from "./store";
import router from './routes'
import "./plugins/fontawesome"
import "./plugins/index"
import "@/plugins/"
import "@shared/plugins/apexcharts"
import "@shared/plugins/formulate";
import titleMixin from '@shared/mixins/title';
import { directive as onClickOutside } from "@directives/clickoutside.js";

Vue.mixin(titleMixin)
Vue.directive("on-click-outside", onClickOutside);


Vue.config.productionTip = false;

export const EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
