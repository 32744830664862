import Vue from "vue";
import VueCookies from "vue-cookies";
import {
  getSearchedRecords,
  insertGraphNodes,
  getManualData,
  getLicenseIds,
  getAllFeatures,
  getInternetSearchCategory,
  createCase,
  postEachEntitySelectedToolData,
  updateCaseName,
  deleteManualData,
  getSelectedToolState,
} from "./services";
import {
  makeCompanyGraph,
  mergeAllNodeWithCentralnode,
  onSearch,
} from "./components/graph/helpers";
import { omit } from "lodash";
import VueRouter from "@/routes";
// import VueRouter from "vue-router";

// const toast = useToast()
export let LightRayStore = {
  state: {
    entitiesData: null,
    caseId: null,
    apiHeaders: {
      "x-case-id": null,
      "x-status": "RUNNING",
      "x-visited": true,
      "x-monitoring": false,
      "x-tool-name": "adverse-media-tool",
    },
    companyAllData: { companySearchData: [] },
    entitiesRelationsAttributesData: {
      attributes: [],
      entities: [],
      relations: [],
    },
    count: 0,
    entitiesList: [],
    entitiesLoader: false,
    featureLoader: false,
    createCaseLoader: false,
    licenseIds: [],
    selectableFeature: {
      features: [],
      internetSearch: [],
      analysis: [],
    },
    internetFeatureForPayload: {},
    lightRayCaseId: "",
    caseDetails: {
      caseId: "",
      neoCaseId: "",
      id: "",
    },
    saveEntityDataLoader: false,
    isEntityDataUpdated: false,
    selectedComponentKey: 0,
    showBuildQuery: false,
    showMainEntityMoreList: false,
    selectedEntityId: "",
    selectedEntityComponentData: [],
    internetSearchData: [],
    analysisData: [],
    selectedToolStateLoader: false,
    clearAllButtonDisabled: false,
    IsRunIntelligenceRun: false,
    selectedToolsState: [],
  },
  mutations: {
    SET_ENTITIES_DATA(state, payload) {
      state.entitiesData = payload;
    },
    SET_NEOTAS_CASE_ID(state) {
      state.caseId = VueCookies.get("neotas_case");
      state.apiHeaders["x-case-id"] = VueCookies.get("neotas_case");
    },
    SET_COMPANY_DATA(state, payload) {
      state.companyAllData = {
        companySearchData: [
          ...state.companyAllData["companySearchData"],
          ...payload.companySearchData,
        ],
      };
    },
    SET_COMPANY_COUNT(state, payload) {
      state.count = payload;
    },
    SET_ENTITIES_RELATIONS_ATTRIBUTES_DATA(state, payload) {
      state.entitiesRelationsAttributesData = payload;
    },
    CLEAR_COMPANY_DATA(state) {
      state.companyAllData = { companySearchData: [] };
    },
    SET_ENTITIES_LIST(state, payload) {
      state.entitiesList = payload;
    },
    SET_LICENSE_IDS(state, payload) {
      state.licenseIds = payload;
    },
    SET_SELECTABLE_FEATURES(state, payload) {
      state.selectableFeature.features = payload.features;
      state.selectableFeature.internetSearch = payload.internetSearch;
      state.selectableFeature.analysis = payload.analysis;
    },
    SET_ENTITIES_LOADER(state, payload) {
      state.entitiesLoader = payload;
    },
    SET_FEATURES_LOADER(state, payload) {
      state.featureLoader = payload;
    },
    SET_CREATE_CASE_LOADER(state, payload) {
      state.createCaseLoader = payload;
    },
    SET_INTERNET_FOR_PAYLOAD(state, payload) {
      state.internetFeatureForPayload = payload;
    },
    SET_LIGHT_RAY_CASE_DETAILS(state, payload) {
      state.caseDetails.caseId = payload.case_id;
      state.caseDetails.neoCaseId = payload.neo_case_id;
      state.caseDetails.id = payload.id;
    },
    SET_FEATURE_DATA_IN_ENTITIES_LIST(state) {
      state.entitiesList = state.entitiesList.map((el) => ({
        ...el,
        selectOutputs: state.selectableFeature.features,
        internetSearch: state.selectableFeature.internetSearch,
        analysis: state.selectableFeature.analysis,
      }));
      state.entitiesList = state.entitiesList.map((el) => {
        if (el.type === "person") {
          return {
            ...el,
            selectOutputs: state.selectableFeature.features.filter((el) =>
              el.supported_entity.includes("person")
            ),
            internetSearch: state.selectableFeature.internetSearch,
            analysis: state.selectableFeature.analysis,
          };
        }
        if (el.type === "company") {
          return {
            ...el,
            selectOutputs: state.selectableFeature.features.filter((el) =>
              el.supported_entity.includes("company")
            ),
            internetSearch: state.selectableFeature.internetSearch,
            analysis: state.selectableFeature.analysis,
          };
        }
        return el;
        // selectOutputs: state.selectableFeature.features,
        // internetSearch: state.selectableFeature.internetSearch,
        // analysis: state.selectableFeature.analysis,
      });
    },
    SET_FIRST_ENTITY_SELECTED(state) {
      // by default ist entity is selected
      state.entitiesList = state.entitiesList.map((entity, index) => ({
        ...entity,
        selected: index === 0 ? true : false,
      }));
    },
    SET_SELECTED_TOOL_ENTITIES_WISE(state, { type, value, id }) {
      state.entitiesList = state.entitiesList.map((el) => {
        if (el.entity_id === state.selectedEntityId) {
          const modData = el[type].map((el) => {
            if (el.id === id) {
              return { ...el, checked: value };
            }
            return el;
          });
          return { ...el, [type]: modData };
        }
        return el;
      });
    },
    SET_UNSELECT_ENTITY(state, position) {
      state.entitiesList = state.entitiesList.map((el, idx) => {
        if (idx === position) {
          return { ...el, selected: false };
        }
        return el;
      });
      // state.entitiesList[position].selected = false;
    },
    SET_SELECT_ENTITY(state, position) {
      state.entitiesList = state.entitiesList.map((el, idx) => {
        if (idx === position) {
          return { ...el, selected: true };
        }
        return el;
      });
    },
    SET_SAVE_ENTITY_LOADER(state, flag) {
      state.saveEntityDataLoader = flag;
    },
    SET_IS_ENTITY_DATA_UPDATED(state, flag) {
      state.isEntityDataUpdated = flag;
    },
    SET_SELECTED_TOOL_STATE_LOADER(state, flag) {
      state.selectedToolStateLoader = flag;
    },
    SET_SELECTED_COMPONENT_KEY(state) {
      state.selectedComponentKey = state.selectedComponentKey + 1
    },
    SET_CLEAR_ALL_BUTTON_DISABLED(state, flag) {
      state.clearAllButtonDisabled = flag;
    },
    SET_SELECTED_TOOL_STATE(state, payload) {
      state.selectedToolsState = payload;
    },
  },
  getters: {
    getEntitiesRelationsAttributesData: (state) =>
      state.entitiesRelationsAttributesData,
    getEntitiesData: (state) => state.entitiesData,
    getCaseId: (state) => state.caseId,
    getApiHeaders: (state) => state.apiHeaders,
    getCompanyAllData: (state) => state.companyAllData,
    getCompanyAllcount: (state) => state.count,
    getLicenseBasedFeatures: (state) => state.selectableFeature,
    getIsAnyFeatureAvailable: (state) => {
      return (
        !!state.selectableFeature?.features?.length ||
        !!state.selectableFeature?.internetSearch?.length ||
        !!state.selectableFeature?.analysis?.length
      );
    },
    getFeatureLoader: (state) => state.featureLoader,
    getEntitiesListLoader: (state) => state.entitiesLoader,
    getCreateCaseLoader: (state) => state.createCaseLoader,
    getLightRayCaseId: (state) => state.caseDetails.neoCaseId,
    getCreateCaseLoading: (state) => state.createCaseLoader,
    getEntitiesList: (state) => state.entitiesList,
    getSelectableFeatures: (state) => state.selectableFeature,
    getInternetSearchPayload: (state) => state.internetFeatureForPayload,
    getSelectedEntityComponentData: (state) =>
      state.selectedEntityComponentData,
    getInternetSearchData: (state) => state.internetSearchData,
    getAnalysisData: (state) => state.analysisData,
    getShowBuildQuery: (state) => state.showBuildQuery,
    getSelectedComponentKey: (state) => state.selectedComponentKey,
    getSaveEntityDataLoader: (state) => state.saveEntityDataLoader,
    getShowMainEntityMoreList: (state) => state.showMainEntityMoreList,
    getClearAllButtonDisabled: (state) => state.clearAllButtonDisabled,
    getIsRunIntelligenceRun: (state) => state.IsRunIntelligenceRun,
    getSelectedToolsState: (state) => state.selectedToolsState,
  },
  actions: {
    setNeotasCaseId({ commit }) {
      commit("SET_NEOTAS_CASE_ID");
    },
    async setSearchData({ commit }, payload) {
      try {
        const companySearchData = await getSearchedRecords(
          payload.companySearchPayload
        );
        let uniqueKey = `Lightray__${payload?.companySearchPayload?.name}__`;
        let centralNodepayload = {
          data: { company_name: payload?.companySearchPayload?.name },
          source: "company",
        };
        let allCompanyData = {
          companySearchData: companySearchData.data.data || [],
        };
        let centralNodes = await onSearch(centralNodepayload, uniqueKey);
        if (companySearchData) {
          let companyGraphData = makeCompanyGraph(
            companySearchData?.data,
            uniqueKey
          );
          let resultGraphData = mergeAllNodeWithCentralnode(
            companyGraphData,
            centralNodes
          );
          let case_id = sessionStorage.getItem("neo_case_id");
          insertGraphNodes({
            case_id,
            nodes: resultGraphData.nodes,
            edges: resultGraphData.links,
          });
        }
        commit("SET_COMPANY_DATA", allCompanyData);
        commit("SET_COMPANY_COUNT", companySearchData.data.total_count);
      } catch (error) {
        Vue.$toast.error(error.response.data.detail);
        console.error("Error fetching data:", error);
      }
    },
    clearSetSearchData({ commit }) {
      commit("CLEAR_COMPANY_DATA");
    },
    getCaseFromSessionStorage() {
      return {
        id: sessionStorage.getItem("id"),
        neo_case_id: sessionStorage.getItem("neo_case_id"),
      };
    },
    async createNewCase({ commit, dispatch }, casePayload) {
      try {
        commit("SET_ENTITIES_LIST", []);
        commit("SET_CREATE_CASE_LOADER", true);
        const res = await createCase(casePayload);
        commit("SET_LIGHT_RAY_CASE_DETAILS", res?.data);
        await dispatch("saveCaseInSessionStorage");
        commit("SET_CREATE_CASE_LOADER", false);
      } catch (err) {
        console.error(err);
        if (err.response) {
          Vue.$toast.error(
            (err && err?.response?.data?.detail) || "Something went wrong."
          );
        } else if (err.request) {
          Vue.$toast.error("Network error: No response from the server");
        } else {
          Vue.$toast.error("Something went wrong.");
          VueRouter.push({ path: "/create-case" });
        }
        commit("SET_CREATE_CASE_LOADER", false);
      }
    },
    async getEntitiesList({ commit, dispatch }) {
      let data = await dispatch("getCaseFromSessionStorage");
      if (data.neo_case_id) {
        commit("SET_ENTITIES_LOADER", true);
        try {
          let case_id = data.neo_case_id;
          let entitiesData = [];
          let main_entity = await getManualData(case_id);
          if (main_entity?.data?.company?.length) {
            let company = main_entity.data.company.map((i) => ({
              ...i,
              type: "company",
            }));
            entitiesData = [...entitiesData, ...company];
          }
          if (main_entity?.data?.person?.length) {
            let person = main_entity.data.person.map((i) => ({
              ...i,
              type: "person",
            }));
            entitiesData = [...entitiesData, ...person];
          }
          entitiesData = entitiesData.sort(
            (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
          );
          commit("SET_ENTITIES_LIST", entitiesData);
          if (entitiesData.length && entitiesData[0]?.company_name) {
            sessionStorage.setItem("case_name", entitiesData[0]?.company_name);
          } else {
            sessionStorage.setItem("case_name", entitiesData[0]?.first_name);
          }
          await dispatch("getSelectedToolState");
          commit("SET_ENTITIES_LOADER", false);
        } catch (err) {
          commit("SET_ENTITIES_LIST", []);
          commit("SET_ENTITIES_LOADER", false);
        }
      }
    },
    async fetchLicenseIds({ commit }) {
      try {
        const licenseData = await getLicenseIds();
        const licenseIds =
          licenseData?.data?.license_details?.map((el) => el?.license_id) || [];
        commit("SET_LICENSE_IDS", licenseIds);
      } catch (err) {
        Vue.$toast.error(err?.response?.data?.detail || "Something went wrong");
      }
    },
    async getSelectableFeature({ commit, state, dispatch }) {
      try {
        commit("SET_FEATURES_LOADER", true);
        let features = [];
        let internetSearch = [];
        let analysis = [];
        await dispatch("fetchLicenseIds");
        if (state.licenseIds?.length) {
          const data = await getAllFeatures(state.licenseIds);
          features = data?.data?.features || [];
          analysis = data?.data?.analyis || [];

          let isInternetSearch = features.find(
            (f) => f.feature_name === "internet_search"
          );
          commit("SET_INTERNET_FOR_PAYLOAD", isInternetSearch);

          if (isInternetSearch) {
            try {
              const response = await getInternetSearchCategory();
              internetSearch = response?.data?.data?.map((item) => ({
                category_id: item.category_id,
                category_name: item.category_name,
                label: item.category_name,
                checked: false,
                id: item.category_id,
              }));
            } catch (err) {
              console.error(err);
              commit("SET_FEATURES_LOADER", false);
              Vue.$toast.error(
                err?.response?.data?.detail || "Something went wrong"
              );
            }
          }

          if (features?.length) {
            features = features.map((f) => ({
              ...f,
              checked: false,
              id: f.feature_name,
              label: f.feature_name === "company_credits" ? "Company Financial Strength" : f.feature_name,
            }));
          }

          if (analysis?.length) {
            analysis = await Promise.all(
              analysis.map(async (el) => ({
                id: el,
                value: el,
                label: await dispatch("getAnalysisMenuName", el),
                checked: false,
              }))
            );
          }
          features = features.filter(
            (f) => f.feature_name !== "internet_search"
          );
        }
        commit("SET_SELECTABLE_FEATURES", {
          features,
          internetSearch,
          analysis,
        });
        commit("SET_FEATURES_LOADER", false);
      } catch (err) {
        console.error(err);
        commit("SET_FEATURES_LOADER", false);
      }
    },
    getAnalysisMenuName(_, name) {
      if (name === "analyzer__entity_recognition") {
        return "Entity Recognition";
      } else if (name === "analyzer__topic_classification") {
        return "Topic Classification";
      } else if (name === "analyzer__core_risk_categorisation") {
        return "Risk Categorisation";
      }
    },
    saveCaseInSessionStorage({ state }) {
      sessionStorage.setItem("id", state.caseDetails.id);
      sessionStorage.setItem("neo_case_id", state.caseDetails.neoCaseId);
    },

    updateEntityListData({ commit, state }) {
      commit("SET_FIRST_ENTITY_SELECTED");
      state.selectedEntityId = state.entitiesList.find(
        (el, idx) => el.selected === true && idx === 0
        ).entity_id;
        commit("SET_FEATURE_DATA_IN_ENTITIES_LIST");
      },
    async saveSelectedData({ state, dispatch, commit }, payload) {
      await commit("SET_SELECTED_TOOL_ENTITIES_WISE", payload);
      const currentSelectedPosition = state.entitiesList
        .map((el) => el.selected)
        .indexOf(true);
       let isAnyToolSelected = await dispatch(
        "isToolSelected",
        currentSelectedPosition
      );
       commit("SET_CLEAR_ALL_BUTTON_DISABLED", !isAnyToolSelected);
    },
    async previousEntity({ state, commit, dispatch }) {
      // only one entity is selected at a time
      const currentSelectedPosition = state.entitiesList
        .map((el) => el.selected)
        .indexOf(true);

      if (currentSelectedPosition > 5) {
        state.showMainEntityMoreList = true;
      } else {
        state.showMainEntityMoreList = false;
      }

      if (currentSelectedPosition - 1 < state.entitiesList.length - 1) {
        state.showBuildQuery = false;
      }

      if (
        currentSelectedPosition < state.entitiesList.length &&
        currentSelectedPosition !== -1 &&
        currentSelectedPosition >= 1
      ) {
        // unselect entity
        commit("SET_UNSELECT_ENTITY", currentSelectedPosition);
        //   this.main_entity[currentSelectedPosition].selected = false;
        // select the entity
        commit("SET_SELECT_ENTITY", currentSelectedPosition - 1);
        // this.main_entity[currentSelectedPosition].selected = false;
        // select the entity
        // this.main_entity[currentSelectedPosition - 1].selected = true;
        // update select query and output component data for selected entity
        // re render the same component when entity change to reflect the change value
        state.selectedComponentKey = state.selectedComponentKey - 1;
        await dispatch("updateComponentData");
      } else {
        return;
      }
    },
    async nextEntity({ state, commit, dispatch }) {
      const currentSelectedPosition = state.entitiesList
        .map((el) => el.selected)
        .indexOf(true);

      if (
        state.entitiesList[currentSelectedPosition].analysis.some(
          (el) => el.checked
        )
      ) {
        // dispatch("isInternetToolSelected", currentSelectedPosition)
        const selected = await dispatch(
          "isInternetToolSelected",
          currentSelectedPosition
        );
        if (!selected) {
          Vue.$toast.error(
            "At least one internet search needs to be selected."
          );
          return;
        }
      }
      let isAnyToolSelected = await dispatch(
        "isToolSelected",
        currentSelectedPosition
      );
      if (!isAnyToolSelected) {
        Vue.$toast.error(
          "At least one internet search or select output needs to be selected."
        );
        return;
      }
      if (state.isEntityDataUpdated) {
        commit("SET_SAVE_ENTITY_LOADER", true);
        // state.saveEntityDataLoader = true;

        const status = await dispatch(
          "postEntityData",
          state.entitiesList[currentSelectedPosition]
        );

        if (!status) {
          return;
        }
        commit("SET_SAVE_ENTITY_LOADER", false);
      }

      if (currentSelectedPosition > 3 && state.entitiesList.length > 5) {
        state.showMainEntityMoreList = true;
      } else {
        state.showMainEntityMoreList = false;
      }

      if (!state.saveEntityDataLoader) {
        if (currentSelectedPosition === state.entitiesList.length - 1) {
          state.showBuildQuery = true;
        }

        if (
          currentSelectedPosition < state.entitiesList.length - 1 &&
          currentSelectedPosition !== -1
        ) {
          // re render the same component when entity change to reflect the change value
          state.selectedComponentKey = state.selectedComponentKey + 1;
          // unselect entity
          commit("SET_UNSELECT_ENTITY", currentSelectedPosition);
          //   this.main_entity[currentSelectedPosition].selected = false;
          // select the entity
          commit("SET_SELECT_ENTITY", currentSelectedPosition + 1);
          //   this.main_entity[currentSelectedPosition + 1].selected = true;
          // update select query and output component data for selected entity

         
        }

        if (state.isEntityDataUpdated) {
          await dispatch("updateComponentData");
          // state.updateComponentData();
        } else {
          await dispatch("updateComponentData");
        }

        state.isEntityDataUpdated = false;
      }
    },
    isInternetToolSelected({ state }, currentSelectedPosition) {
      let selected = false;
      if (state.entitiesList[currentSelectedPosition].analysis.length > 0) {
        selected = state.entitiesList[
          currentSelectedPosition
        ].internetSearch.some((el) => el.checked === true);
      }
      return selected;
    },
    isToolSelected({ state }, currentSelectedPosition) {
      let isAnyToolSelected = false;
      if (
        state.entitiesList[currentSelectedPosition].selectOutputs.length > 0
      ) {
        isAnyToolSelected = state.entitiesList[
          currentSelectedPosition
        ].selectOutputs.some((el) => el.checked === true);
      }
      if (
        !isAnyToolSelected &&
        state.entitiesList[currentSelectedPosition].internetSearch.length > 0
      ) {
        isAnyToolSelected = state.entitiesList[
          currentSelectedPosition
        ].internetSearch.some((el) => el.checked === true);
      }
      return isAnyToolSelected;
    },
    async postEntityData({ commit, dispatch, getters }, entityData) {
      let payload = {
        case_id: sessionStorage.getItem("neo_case_id") || "",
        features: [],
        category: [],
        entity_id: entityData?.entity_id || "",
        config: {
          ner: false,
          topic_classification: false,
          risk_categorisation: false,
          // esg_categorisation: false,
        },
      };
      const isAnySelectOutputChecked = entityData?.selectOutputs.some(
        (el) => el.checked
      );
      const isAnyInternetSearchChecked = entityData?.internetSearch.some(
        (el) => el.checked
      );

      if (!isAnySelectOutputChecked && !isAnyInternetSearchChecked) {
        return;
      }
      if (isAnySelectOutputChecked) {
        payload.features = await dispatch("getPayload", {
          data: entityData.selectOutputs,
          type: "selectOutput",
        });
      }
      const isAnalysisData = entityData?.analysis.some((el) => el.checked);
      if (isAnalysisData) {
        payload.config.ner = await dispatch("getConfigValue", {
          type: "analyzer__entity_recognition",
          entityData,
        });
        payload.config.topic_classification = await dispatch("getConfigValue", {
          type: "analyzer__topic_classification",
          entityData,
        });
        payload.config.risk_categorisation = await dispatch("getConfigValue", {
          type: "analyzer__core_risk_categorisation",
          entityData,
        });
        // payload.config.esg_categorisation = await dispatch("getConfigValue", {
        //   type: "riskCategorisation",
        //   entityData,
        // });
      }
      if (isAnyInternetSearchChecked) {
        payload.category = await dispatch("getPayload", {
          data: entityData.internetSearch,
          type: "internetSearch",
        });
        payload.features.push(getters.getInternetSearchPayload);
      }
      let dataPost = false;
      try {
        const data = await postEachEntitySelectedToolData(payload);
        if (data?.data?.status) {
          dataPost = true;
        }
      } catch (err) {
        console.error(err);
        Vue.$toast.error(err?.response?.data?.detail || "Something went wrong");
        commit("SET_SAVE_ENTITY_LOADER", false);
        dataPost = false;
      }
      return dataPost;
    },
    async updateComponentData({ state, dispatch, commit }) {
      state.selectedEntityId = state.entitiesList.find(
        (el) => el.selected === true
      ).entity_id;
      state.selectedEntityComponentData = state.entitiesList
        .find((el) => el.entity_id === state.selectedEntityId)
        .selectOutputs.map((el) => el);
      state.internetSearchData = state.entitiesList
        .find((el) => el.entity_id === state.selectedEntityId)
        .internetSearch.map((el) => el);
      state.analysisData = state.entitiesList
        .find((el) => el.entity_id === state.selectedEntityId)
        .analysis.map((el) => el);
      state.IsRunIntelligenceRun = state.entitiesList.find((el) => el.entity_id === state.selectedEntityId).run_intelligence;
      const currentSelectedPosition = state.entitiesList
      .map((el) => el.selected)
      .indexOf(true);
      let isAnyToolSelected = await dispatch(
        "isToolSelected",
        currentSelectedPosition
      );
       commit("SET_CLEAR_ALL_BUTTON_DISABLED", !isAnyToolSelected);
    },
    async manageComponentData({ state, getters, dispatch, commit }) {
      if (
        !state.selectableFeature?.features?.length ||
        !state.selectableFeature?.internetSearch?.length ||
        !state.selectableFeature?.analysis?.length
      ) {
        const data = await getters.getSelectableFeatures;
        state.selectableFeature.features = data?.features;
        state.selectableFeature.internetSearch = data?.internetSearch;
        state.selectableFeature.analysis = data?.analysis;
      }

      if (state.entitiesList?.length) {
      //  const id =  state.selectedEntityId = state.entitiesList.find(
      //   (el, idx) => el.selected === true && idx === 0
      // ).entity_id;
        await dispatch("updateEntityListData");
        // state.selectedEntityId = state.entitiesList.find(
        //   (el, idx) => el.selected === true && idx === 0
        // ).entity_id;
      }
      if (state.entitiesList?.length === 1) {
        state.showBuildQuery = true;
      } else {
        state.showBuildQuery = false;
      }

      state.selectedEntityComponentData = state.selectableFeature?.features;
      state.internetSearchData = state.selectableFeature?.internetSearch;
      state.analysisData = state.selectableFeature?.analysis;
      state.selectedComponentKey = state.selectedComponentKey + 1;
      const currentSelectedPosition = state.entitiesList
        .map((el) => el.selected)
        .indexOf(true);
       let isAnyToolSelected = await dispatch(
        "isToolSelected",
        currentSelectedPosition
      );
       commit("SET_CLEAR_ALL_BUTTON_DISABLED", !isAnyToolSelected);
      await dispatch("updateComponentData");
    },
    async saveEntity({ state, dispatch }, payload={}) {
      if (state.entitiesList.length) {
        if (state.entitiesList.length === 1) {
          let isAnyToolSelected = await dispatch("isToolSelected", 0);

          if (!isAnyToolSelected) {
            Vue.$toast.error("Please select at least one tool.");
            return;
          }

          const currentSelectedPosition = state.entitiesList
            .map((el) => el.selected)
            .indexOf(true);
          await dispatch(
            "postEntityData",
            state.entitiesList[currentSelectedPosition]
          );
        }
        // if (state.entitiesList.length === 1) {
        //   const currentSelectedPosition = this.entitiesList
        //     .map((el) => el.selected)
        //     .indexOf(true);
        //   await this.postEntityData(this.entitiesList[currentSelectedPosition]);
        // }
        let data = await dispatch("getCaseFromSessionStorage");
        let customPayload = {
          case_name:
            state.entitiesList[0].first_name ||
            state.entitiesList[0].company_name,
          neo_case_id: data.id,
        };
        updateCaseName(customPayload, data.neo_case_id);
        if(!payload?.type) {
          VueRouter.push({
            name: "entity-details",
            params: { tool: "Entity-Details" },
            query: {
              case_id:
                VueRouter?.currentRoute?.query?.case_id || data.neo_case_id,
              name:
                state.entitiesList[0].first_name ||
                state.entitiesList[0].company_name,
            },
          });
        }
      }
    },
    async deleteEntity({ state, dispatch, commit }, entity_id) {

      try {
        const currentSelectedPosition = state.entitiesList
          .map((el) => el.selected)
          .indexOf(true);
        const currentClickedPosition = state.entitiesList.findIndex(
          (el) => el.entity_id === entity_id
        );
        let deletedPos = await dispatch("findPos", entity_id);
        if (deletedPos || deletedPos === 0) {
          deletedPos =
            deletedPos === state.entitiesList.length - 1
              ? deletedPos - 1
              : deletedPos;
        }
        let deleteData = await deleteManualData(entity_id);
        if (deleteData) {
         
          Vue.$toast.error("Deleted Successfully");
          await dispatch("getEntitiesList");
          await commit("SET_FEATURE_DATA_IN_ENTITIES_LIST");

          if (state.entitiesList?.length === 1) {
            state.showBuildQuery = true;
            await commit("SET_SELECT_ENTITY", 0);
            if(state.entitiesList.length) {
              await dispatch("updateComponentData");
            }
          } else {
              let payload = {
                deletedPos,
                currentSelectedPosition,
                currentClickedPosition
              }
            await dispatch(
              "updateEntitySelectedPosition",
             payload
            );
            state.showBuildQuery = false;
          }

          // remove the more list option form entity ribbon
          if (state.entitiesList.length < 5) {
            state.showMainEntityMoreList = false;
          }
          if (!state.entitiesList.length) {
            sessionStorage.removeItem("case_name");
            // Vue.$forceUpdate();
            VueRouter.push({
              name: "add-multientities",
              query: {
                package_id: VueRouter?.currentRoute?.query?.package_id,
                client_id: VueRouter?.currentRoute?.query?.client_id,
                t: new Date().getTime(),
              },
            });
          } else {
            state.entitiesList.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            );
            if (state.entitiesList.length) {
              sessionStorage.setItem(
                "case_name",
                state.entitiesList[0]?.company_name ||
                  state.entitiesList[0]?.person
              );
            }
          }
        } else {
          Vue.$toast.warning("Automated Entity is not deleted");
        }
        await dispatch("getSelectedToolState");
      } catch (err) {
        console.error("error", err);
        Vue.$toast.error("Something went wrong");
      }
    },
    findPos({ state }, entity_id) {
      let pos = false;
      if (state.entitiesList.length > 1) {
        for (const el of state.entitiesList) {
          if (el.entity_id === entity_id && el.selected) {
            pos = state.entitiesList.indexOf(el);
            break;
          }
        }
      }
      return pos;
    },
    async updateEntitySelectedPosition(
      { state, commit, dispatch },
      {deletedPos,
      currentSelectedPosition,
      currentClickedPosition}
    ) {
      if (deletedPos !== false && deletedPos !== 0) {
        commit("SET_SELECT_ENTITY", deletedPos);
      } else if (deletedPos !== false && deletedPos === 0) {
        await commit("SET_SELECT_ENTITY", deletedPos);
      } else if (
        deletedPos !== false &&
        deletedPos !== 0 &&
        deletedPos === state.entitiesList.length - 1
      ) {
        commit("SET_SELECT_ENTITY", deletedPos - 1);
      } else if (
        currentSelectedPosition !== 0 &&
        currentSelectedPosition > currentClickedPosition
      ) {
        commit("SET_SELECT_ENTITY", currentSelectedPosition - 1);
      } else if (
        currentSelectedPosition !== 0 &&
        currentSelectedPosition < currentClickedPosition
      ) {
        commit("SET_SELECT_ENTITY", currentSelectedPosition);
      } else if (currentSelectedPosition === 0 && deletedPos !== false) {
        commit("SET_SELECT_ENTITY", currentSelectedPosition);
      } else if (deletedPos === false && currentSelectedPosition === 0) {
        commit("SET_SELECT_ENTITY", currentSelectedPosition);
      } else if(currentSelectedPosition === 0) {
        commit("SET_SELECT_ENTITY", 0);
      }
      if(state.entitiesList.length) {
        await dispatch("updateComponentData");
      }
     
    },
    toggleMoreList({ state }, flag) {
      state.showMainEntityMoreList(flag);
    },
    getPayload(_, { data, type }) {
      switch (type) {
        case "selectOutput": {
          let selectOutputData = data
            .filter((el) => el.checked)
            .map((item) => omit(item, ["id", "checked", "label"]));
          return selectOutputData;
        }
        case "internetSearch": {
          let internetSearchData = data
            .filter((el) => el.checked)
            .map((item) => omit(item, ["id", "checked", "label"]));
          return internetSearchData;
        }
      }
    },
    async getSelectedToolState({ commit, dispatch, state }) {
      commit("SET_SELECTED_TOOL_STATE_LOADER", true);
      let data = await dispatch("getCaseFromSessionStorage");
      // const case_id = getters.getLightRayCaseId
      try {
        const response = await getSelectedToolState(data.neo_case_id);
        commit("SET_SELECTED_TOOL_STATE", response?.data?.result);
        
        let selectedFeature = [];
        let selectedInternet = [];
        let selectedAnalysis = [];
        response.data.result.forEach((result) => {
          selectedFeature.push({
            id: result?.entity_id,
            features: result?.features?.map((el) => el?.feature_name),
          });
        });
        response.data.result.forEach((result) => {
          selectedInternet.push({
            id: result?.entity_id,
            category: result?.category?.map((el) => el?.category_name),
          });
        });
        response.data.result.forEach((result) => {
          selectedAnalysis.push({
            id: result?.entity_id,
            analysis: result?.config,
          });
        });
        // const selectedFeature = res.result.map((el) => el.features.map((f) => ({[el.entity_id]: f.feature_name})));
       
        state.entitiesList = state.entitiesList.map((entity) => {
          if (entity.selectOutputs.length) {
            let updatedSelectedFeature = entity.selectOutputs.map((output) => {
              let findSelectedFeature = selectedFeature.find(
                (f) => f.id === entity.entity_id
              );
              if (findSelectedFeature) {
                let isChecked = findSelectedFeature.features.includes(
                  output.feature_name
                );
                return { ...output, checked: isChecked };
              }
              return output;
            });
            return { ...entity, selectOutputs: updatedSelectedFeature };
          }
          return entity;
        });

        state.entitiesList = state.entitiesList.map((entity) => {
          if (entity.internetSearch.length) {
            // let updatedSelectedFeature =  dispatch("setCheckedValue", entity, selectedFeature);
            let updatedSelectedFeature = entity.internetSearch.map(
              (internet) => {
                let findSelectedFeature = selectedInternet.find(
                  (f) => f.id === entity.entity_id
                );
                if (findSelectedFeature) {
                  let isChecked = findSelectedFeature.category.includes(
                    internet.category_name
                  );
                  return { ...internet, checked: isChecked };
                }
                return internet;
              }
            );
            return { ...entity, internetSearch: updatedSelectedFeature };
          }
          return entity;
        });

        state.entitiesList = state.entitiesList.map((entity) => {
          if (entity.analysis.length) {
            // let updatedSelectedFeature =  dispatch("setCheckedValue", entity, selectedFeature);
            let updatedSelectedFeature = entity.analysis.map((analysis) => {
              let findSelectedFeature = selectedAnalysis.find(
                (f) => f.id === entity.entity_id
              );
              if (
                analysis.id === "analyzer__topic_classification" &&
                findSelectedFeature
              ) {
                return {
                  ...analysis,
                  checked: findSelectedFeature.analysis["topic_classification"],
                };
              } else if (
                analysis.id === "analyzer__core_risk_categorisation" &&
                findSelectedFeature
              ) {
                return {
                  ...analysis,
                  checked: findSelectedFeature.analysis["risk_categorisation"],
                };
              } else if (
                analysis.id === "analyzer__entity_recognition" &&
                findSelectedFeature
              ) {
                return {
                  ...analysis,
                  checked: findSelectedFeature.analysis["esg_categorisation"],
                };
              } else {
                return analysis;
              }
             
            });
            return { ...entity, analysis: updatedSelectedFeature };
          }
          return entity;
        });
        await dispatch("updateComponentData");
        commit("SET_SELECTED_TOOL_STATE_LOADER", false);
      } catch (err) {
        commit("SET_SELECTED_TOOL_STATE_LOADER", false);
      }
    },
    getConfigValue(_, { entityData, type }) {
      let data = entityData?.analysis.find((el) => el.id === type);
      return data && data.checked;
    },
  
  },
};
